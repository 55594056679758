import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import MyDrawer from "./MyDrawer";
import {observable} from "mobx";
import User from "./User";
import {Affix, Button, Form, Skeleton, Space, Input, Divider} from "antd";
import Api from "./Api";
import {addRoute} from "./Router";
import Device from "./Device";
import HtmlDiv from "./HtmlDiv";
import {useForm} from "antd/es/form/Form.js";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import BotResult from "./BotResult.js";
import {Showable} from "./Switcher.js";
import {ContainerOutlined} from "@ant-design/icons";
import {Bot} from "lucide-react";


export const LawDrawerStore = observable({
  law: null,
  opened: false,
  locate: null,

  load: async (id, locate) => {
    LawDrawerStore.locate = locate;
    LawDrawerStore.opened = true;

    let data = (await Api.get("/laws/" + id)).data;

    LawDrawerStore.law = data.law;
  },

  close: () => {
    LawDrawerStore.locate = null;
    LawDrawerStore.opened = false;
    LawDrawerStore.law = null;
    addRoute({}, null, "/");
  }
});

let LawChat = observer(({law, setTab}) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);

  let [form] = useForm();

  let search = async (params) => {
    setLoading(true);
    setResult(false);
    callWithCatch({
      url: `/laws/${law.id}/chat`,
      method: 'post',
      params: params,
      onErr: () => {
        setLoading(false)
      },
      onSuccess: data => {
        let sub;
        if (data.text) {
          setLoading(false);
          setResult(data)
        }

        if (data.sub_to)
          sub = MyWS(data.sub_to, r => {
            setResult(r)
            if (r.done) {
              setLoading(false);
              sub.unsubscribe();
            }
          })
      }
    })
  }

  let gotoLink = (n, k, v) => {
    return <a
        key={k || n}
        className="rtl-bidi"
        onClick={(e) => {
          if (Device.xs) {
            setTab('law');
            _.delay(() => document.getElementById("סעיף_" +n).scrollIntoView({behavior: 'instant', block: 'center'}), 100);
          }
          else
            document.getElementById("סעיף_" +n).scrollIntoView({behavior: 'instant', block: 'center'});
        }}
    >{v || n}</a>
  }

  let render = (result) => {
    return result.parts.map((part, i) => {
      if (part.num) {
        return gotoLink(part.num, i, part.text)
      }
      else
        return <span key={i} dangerouslySetInnerHTML={{__html: part.text}}></span>
    })
  }

  let inputSize = Device.xs ? "medium" : "large";

  return <div className="space-y-4 h-full flex flex-col">
    <div className="border-b border-gray-200 space-y-2">
      {law.questions.map((q, i) => <div key={i}><a onClick={() => search({q})}>{q}</a></div>)}
      <Form form={form} onFinish={search} layout="vertical" initialValues={{}}>
        <div className="flex gap">
          <Form.Item name="q" label="" style={{width: "100%"}}>
            <Input style={{width: "100%"}} autoFocus
                   size={inputSize}
                   placeholder="מה רצית לשאול?" onPressEnter={() => form.submit()}/>
          </Form.Item>
          <Button type="primary" size={inputSize} onClick={() => form.submit()}>שאלו</Button>
        </div>
      </Form>
    </div>
    <div className="h-full overflow-y-auto flex-grow zscroll-shadows-gray">
      <BotResult result={result} loading={loading} render={render}/>
    </div>
  </div>
});

let LawDoc = observer(({law}) => {
  let toWords = (s) => _.filter(s.split(/\s+/g), s => s.length > 3)

  useEffect(() => {
    let {locate} = LawDrawerStore;

    setTimeout(() => {
      if (!locate || !locate.length)
        return;

      let lw = toWords(locate);

      let els = _.map(document.querySelectorAll('.law-content1, .law-content2, .law-content3, .law-content4, .law-content5'), el => [el, toWords(el.innerText)]);

      let result = _.maxBy(els, el => _.intersection(lw, el[1]).length)

      if (result) {
        result[0].classList.add("line-selected");
        result[0].scrollIntoView({behavior: 'instant', block: 'center'});
      }
    }, 32)
  }, [law]);

  return <div>
    <HtmlDiv html={law.html}/>
    <br/>
    <hr/>
    <ul id="law-footer-info">
      <li>הטקסט לקוח <a target="_blank"
                        href="https://he.wikisource.org/wiki/%D7%A1%D7%A4%D7%A8_%D7%94%D7%97%D7%95%D7%A7%D7%99%D7%9D_%D7%94%D7%A4%D7%AA%D7%95%D7%97">מספר
        החוקים הפתוח</a></li>
      <li>הטקסט מוגש בכפוף לרישיון <a target="_blank"
                                      href="https://creativecommons.org/licenses/by-sa/3.0/deed.he">Creative
        Commons ייחוס-שיתוף זהה 3.0</a>;
        ייתכן שישנם תנאים נוספים.
        ר' את <a target="_blank" href="https://foundation.wikimedia.org/wiki/Terms_of_Use">תנאי
          השימוש</a> לפרטים.
      </li>
    </ul>
  </div>
});

export const LawDrawer = observer(({}) => {
  let law = LawDrawerStore.law;
  const [tab, setTab] = useState('law');

  if (!LawDrawerStore.opened)
    return null;

  let inner = <Skeleton active />;
  let header;

  let tabs;
  let withChat = true;

  if (law) {
    header = <div className="truncate-text" style={{width: "100%"}}>
      <div><b>{law && law.title}</b></div>
    </div>

    inner = <div>
      <div className="flex gap-4">
        <Showable v={withChat}>
          <div className="w-[400px] flex-shrink-0 bg-gray-100 border-l border-gray-200 pt-4 px-4 space-y-4 max-h-[90vh]">
            <LawChat law={law} />
          </div>
        </Showable>
        <div className="flex-grow">
          <div className="law-drawer h-[90vh] md:overflow-y-auto">
            <LawDoc law={law} />
          </div>
        </div>
      </div>
    </div>

    if (Device.xs && withChat) {
      tabs = [
        ["law", "ה" + law.hebrew_kind, <ContainerOutlined />],
        ["chat", "ניתוחי AI", <Bot />,]
      ]

      inner = <div>
        <div className={"law-drawer " + (tab !== 'law' && "hidden")}>
          <LawDoc law={law} />
        </div>
        <div className={(tab !== 'chat' && "hidden")}>
          <LawChat law={law} setTab={setTab} />
        </div>
      </div>

      // if (tab === 'chat')
      //   inner = <LawChat law={law} />;
      // else
      //   inner = <LawDoc law={law} />;
    }
  }

  return <MyDrawer inner={inner} tabs={tabs} mode={tab} handleModeChange={setTab} close={LawDrawerStore.close} header={header} drawerSize={withChat && "80%"}/>;
})