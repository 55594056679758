import {Space, Spin} from "antd";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import React from "react";

export default ({result, loading, render, fontSize}) => {
    return <div className="w-full sm:flex-grow">
        {result &&
            <div style={{fontSize: fontSize || "16px", lineHeight: "28px", whiteSpace: "pre-line"}} className="md:shadow-md md:p-2">
                <div>
                    <Space><Bot/><span>תשובה:</span></Space>
                </div>
                { render ? render(result) : <HtmlDiv html={result.text}/> }
                <br/>
                {result.done &&
                    <div style={{fontSize: "10px", color: "#9CA3AF"}}>הAI בבטא ויכול לעשות טעויות. אנא ודאו את תשובותיו
                        עם הפסיקה והחקיקה.</div>}
            </div>}
        {loading && !result && <div className="md:p-2"><Space><Bot/><Spin/></Space></div>}
    </div>
}
