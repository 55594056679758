import React from "react";

import { useEffect, useState } from "react";
import {Alert, Button, Card, Col, Divider, Form, List, Radio, Result, Row, Space, Steps, Checkbox} from "antd";
const { Step } = Steps;
import {CheckCircleTwoTone, LockOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Logo from "../../assets/images/dinrega-logo.png";
import axios from "axios";
import {observable} from "mobx";
import {observer} from "mobx-react";
import OurCustomers from "./OurCustomers";

let mobile = window.innerWidth < 450;

window.addEventListener('message', function (result) {
  if (result.origin === 'https://meshulam.co.il' || result.origin === 'https://sandbox.meshulam.co.il') {
    switch (result.data.action) {
      case 'close' : {
        document.getElementsByTagName('iframe')[0].style.setProperty('display', 'none');
        break;
      }
      case 'payment' : {
        if (result.data.status == 1) {
          // success
        }
        break;
      }
      case 'failed_to_load_page': {
        break;
      }
    }
  }
});

function useResponsiveFontSize() {
  const getFontSize = () => (mobile ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

let allPlans = [
  { name: "מנוי אישי חודשי", price: 119, amount: 1, link: "https://meshulam.co.il/quick_payment?b=52b28d51b5add34f7d9e51caded7e862", monthly: true },
  { name: "מנוי אישי שנתי", price: 79, amount: 12, link: "https://meshulam.co.il/s/c2153ac4-9193-e3fe-6b9c-bc502877a257", monthly: false },
  { name: "עמדה משרדית חודשי", price: 149, amount: 1, link: "https://meshulam.co.il/quick_payment?b=05ffae2f7ec312b03086b5ec855360bd", monthly: true },
  { name: "עמדה משרדית שנתי", price: 99, amount: 12, link: "https://meshulam.co.il/s/3fa80185-f77d-4cf4-ab3c-a5869dd0f3fc", monthly: false },
];

export const store = observable({
  state: "plans",
  plan: null,
  terms: false,
  monthly: false,
});

const PaymentForm = observer(props => {
  let plan = _.find(allPlans, {price: store.plan});

  let subtotal = plan.price * plan.amount;
  let maam = Math.round(subtotal * 0.18 * 100) / 100;
  let total = Math.round((subtotal + maam) * 100) / 100;

  let s = n => <td>{n}<span className="nis">ש״ח</span></td>

  let setTerms = () => store.terms = !store.terms;

  return <div>
    <h1>{plan.name}</h1>
    <Row gutter={64}>
      <Col flex={1}>
        <table className="invoice-table">
          <tbody>
            <tr>
              <td></td>
              <td>מחיר</td>
              <td>כמות</td>
              <td>סה״כ</td>
            </tr>
            <tr>
              <td>תשלום לחודש</td>
              {s(plan.price)}
              <td>{plan.amount}</td>
              {s(subtotal)}
            </tr>
            <tr>
              <td>מע״מ</td>
              <td></td>
              <td></td>
              {s(maam)}
            </tr>
            <tr>
              <td>סה״כ לתשלום</td>
              <td></td>
              <td></td>
              {s(total)}
            </tr>
          </tbody>
        </table>
        <br/>
        <div style={{marginBottom: "0.5rem"}}>
          <Checkbox checked={store.terms} onChange={setTerms}>
            קראתי ואני מסכימ/ה ל
            <a href="/terms" target="_blank">תנאי השימוש</a>
          </Checkbox>
        </div>
        <Space>
          <Button size="large" disabled={!store.terms} type="primary" href={plan.link} target="_blank">מעבר לתשלום</Button>
          <Button size="large" onClick={() => store.plan = null}>חזרה</Button>
        </Space>
      </Col>
    </Row>
  </div>
});

const Plans = observer(props => {
  let strike = <span style={{color:'black', textDecoration: "line-through"}}>
    <span style={{color:'gray'}}>948</span>
  </span>

  // <span>{strike} <b>790</b> <b>ש״ח לשנה</b></span>

  let plans = _.filter(allPlans, { monthly: store.monthly });

  if (!mobile)
    plans = _.reverse(plans);

  return <>
    <Radio.Group onChange={() => store.monthly = !store.monthly} value={store.monthly ? "monthly" : "yearly"} buttonStyle="solid" size="large">
      <Radio.Button value="monthly">חודשי</Radio.Button>
      <Radio.Button value="yearly">שנתי</Radio.Button>
    </Radio.Group>
    <br/>
    <br/>
    <Row gutter={32}>
      <Col flex={1}>
        <Subscription {...plans[0]} />
      </Col>
      <Col flex={1}>
        <Subscription {...plans[1]} />
      </Col>
    </Row>
    <div style={{fontSize: "0.75rem", color: "gray", margin: "0.5rem"}}>
      **המחירים לא כוללים מע"מ.
    </div>
  </>
});

const Subscription = ({name, title, price, link}) => {
  let data = [
    "ניתן להתחבר מכל מחשב ומהטלפון",
    "חיפוש בכתיבה חופשית בכל הערכאות",
    "עדכונים שוטפים של פסיקה חדשה",
    // "מעל 100,000 כתבי טענות",
    "דוגמאות של כתבי טענות",
    "חקיקה",
    "תקציר אוטומטי לכל מסמך",
    "כלים מתקדמים לניתוח פסקי דין",
  ]

  if (price === 99 || price === 149) {
    data.unshift('עמדה שיתופית לכל צוות המשרד')
  }
  else
    data.unshift('עבור משתמש אחד בלבד')

  title = <span><b  style={{fontSize: "1.5rem"}}>{price}</b>ש״ח בחודש</span>

  if (store.monthly)
    data.push("תשלום חודשי (לא מתחדש אוטומטית) - ללא התחייבות!")
  else
    data.push("תשלום חד פעמי - ל12 חודשים!")

  let setPlan = () => store.plan = price;

  let cta = "הרשמו ל" + name;

  return <Card title={name} extra={mobile ? "" : title} style={{marginBottom: "1rem"}}>
    {mobile && <div style={{marginBottom: "1rem"}}>{title}</div>}
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize: "1.5rem"}}/>}
            title={item}
          />
        </List.Item>
      )}/>
    <Button size="large" type="primary" style={{marginTop: "0.5rem"}} onClick={setPlan}>{cta}</Button>
  </Card>
}

export default observer(props => {
  let inner;
  if (store.plan)
    inner = <PaymentForm />;
  else
    inner = <Plans />

  let numLink  = mobile ? <a target="_blank" href="https://api.whatsapp.com/send?phone=972545764700">054-5764700</a> : "054-5764700";
  let phoneLink  = mobile ? <a target="_blank" href="tel:0774008177">077-4008177</a> : "077-4008177";

  let notice;
  if (gon.user.status === "pay1" || gon.user.status === "pay2")
    notice = <Alert style={{width: "100%", marginBottom: "1rem"}}
                    message="תקופת הנסיון הסתיימה"
                    description="להמשך השימוש באתר, אנא הרשמו למנוי חודשי/שנתי"
                    type="warning"
                    showIcon
    />;

  return <div>
    { notice }
    { inner }
    <Divider />
    <div>
      מתלבטים? רוצים להתייעץ?<br/>
      חייגו {phoneLink}{mobile && <br />} או בווטסאפ {numLink}<br/>
      <br />
    </div>
    <OurCustomers />
  </div>
});